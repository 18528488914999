@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';

.root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 20rem;
}

.logo {
    margin: 0 auto;
}

.mask {
    animation: mask 1.3s ease infinite;
}

.progressBar {
    background: colors.$color--gray-light;
    height: 2px;

    &::after {
        animation: progress 6s infinite;
        background: colors.$color--black;
        content: '';
        display: block;
        height: 100%;
    }
}

@keyframes progress {
    from { width: 0 }

    to   { width: 100% }
}

@keyframes mask {
    from {transform: translateX(0)}

    to {transform: translateX(280px)}
}
