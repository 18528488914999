@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.lowestPrice {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    position: relative;
    z-index: 2;

    @include responsive.media('>tablet') {
        margin-bottom: 0.5rem;
        margin-top: -0.5rem;
    }

    .lowestPriceTooltip {
        margin-top: 0.625rem;
        right: -1.125rem;

        @include responsive.media('<tablet') {
            left: -1rem;
            width: 16.25rem;
        }

        @include responsive.media('<29.875rem') {
            left: 50%;
            margin-left: calc((260px / 2 - 0.12rem) * -1);
        }
    }
}

.lowestPriceText {
    margin-right: 0.24rem;
}

.lowestPriceNotification {
    margin: 0.5rem 0;
}
