@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.root {
    display: none;
    margin: auto;

    @include responsive.media('>tablet', 'pointer-fine') {
        display: flex;
    }
}

.visuallyHidden {
    @include mixins.a11y-visually-hidden();
    margin: 0;
}
