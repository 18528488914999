@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.agreement {
    margin-left: 2.2rem;
}

.newsletterWrapper {
    display: grid;
    font-size: 0.875rem;
    gap: 0.5rem;
}

.privacyPolicyLink {
    @include mixins.link-underline(
        $baseColor: colors.$text-color--primary,
        $hoverColor: colors.$text-color--primary,
        $hasUnderline: true
    );
}
