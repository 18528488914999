@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndex;
@use '@/styles/resources/responsive';
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/utils/white-header' as whiteHeader;

.submenu {
    background-color: colors.$color--wild-sand;
    box-shadow: variables.$header--dropdown-box-shadow;
    left: 0;
    margin: initial;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition:
        visibility 0s linear 0s,
        opacity 0.25s ease-in-out,
        top 0.2s ease-out;
    visibility: hidden;
    width: initial;
    will-change: opacity, top, visibility;
    z-index: zIndex.$index--mega-menu-submenu-hidden;
}

// White header design
@include whiteHeader.white-header-media{
            .submenu {
                background-color: var(--color--white, #fff);
            }

}

.submenuList {
    display: flex;
    gap: 1rem;
    max-width: 80rem;
    padding: 1.4rem 1rem;
}

.submenuItems {
    flex: 1 1 25%;
    flex-direction: column;
}
