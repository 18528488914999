@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.wrapper {
    position: relative;

    @include vars.mq-small {
        height: 100vw;
        min-width: 100vw;
        scroll-snap-align: start;
    }

    > img {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
}

.videoBlock {
    video {
        height: 100%;
        object-fit: cover;
    }

    @include vars.mq-medium-up {
            position: absolute;
    }
}

.mainImage {
    grid-column: span 2;
}
