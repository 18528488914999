@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndex;

.enter {
    transition-duration: 384ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.exit {
    transition-duration: 192ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.hidden {
    opacity: 0;
    visibility: hidden;
}

.visible {
    opacity: 1;
    visibility: visible;
}

.root {
    composes: exit hidden;
    background-color: colors.$color--white;
    bottom: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-width: 25rem;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(100%, 0, 0);
    transition-property: opacity, transform, visibility;
    width: 100%;
    z-index: zIndex.$index--header;
}

.root_open {
    composes: root enter visible;
    transform: translate3d(0, 0, 0);
}

.header {
    align-content: center;
    display: grid;
    grid-auto-columns: 3.5rem;
    grid-auto-flow: column;
    grid-auto-rows: 3.5rem;
    grid-template-columns: 3.5rem 1fr 3.5rem;
    height: variables.$header-height;
    position: relative;
    z-index: zIndex.$index--main-content;
}

.body {
    composes: exit visible;
    overflow: hidden auto;
    position: relative;
    transition-property: opacity, visibility;
}

.body_masked {
    composes: body enter hidden;
}

.footer {
    background-color: colors.$color--gray-darkest;
    color: colors.$color--white;
    height: variables.$footer-height;
}

.modal {
    composes: exit hidden;
    background-color: colors.$color--white;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: auto;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: variables.$header-height;
    transform: translate3d(100%, 0, 0);
    transition-property: opacity, transform, visibility;
}

.modal_open {
    composes: modal enter visible;
    transform: translate3d(0, 0, 0);
}
