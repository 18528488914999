@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';

.wrapper {
    bottom: 1.5rem;
    display: flex;
    gap: 0.5rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    @include vars.mq-medium-up {
        display: none;
    }
}

.item {
    border: 0.125rem solid colors.$color--gray;
    border-radius: 50%;
    height: 0.5rem;

    transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
    width: 0.5rem;
}

.itemActive.item {
    background-color: var(--brand-color--primary, colors.$brand-color--primary);
    border-color: var(--brand-color--primary, colors.$brand-color--primary);
}
