@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/variables';

.error {
    align-items: center;
    border-bottom-left-radius: variables.$border-radius;
    border-bottom-right-radius: variables.$border-radius;
    color: colors.$message-text--error;
    display: flex;
    font-size: 0.75rem;
    gap: 0.25rem;
    padding: 0.5rem 0.85rem;

    > svg {
        min-width: 1em;
    }
}
