@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

@include vars.mq-medium-up {
    .parent {
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
    }

    .img {
        cursor: zoom-in;
        position: absolute;
        transition: transform .3s ease-in-out;
    }

    .active {
        cursor: zoom-out;
    }
}
