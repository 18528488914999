@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.wrapper {
    position: relative;
}

.imageContainer {
    @include vars.mq-small {
        display: flex;
        max-width: 100vw;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
    }

    @include vars.mq-medium-up {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
        max-height: 64vw;
        overflow-x: auto;
    }

    @include vars.mq-xlarge-up {
        max-height: 1350px;
    }

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}
