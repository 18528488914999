@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.root {
    display: grid;
}

.formSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
}

.form {
    @include mixins.form-grid();

    color: colors.$text-color--primary;
}

.signInCallToActionWrapper {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.signInLink {
    @include mixins.link-underline();
}

.signInSection,
.applyProCustomerSection {
    align-items: center;
    border-top: 1px solid colors.$border-color--primary;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
    padding-top: 1rem;
    text-align: center;
}

.formTitle {
    @include mixins.heading-1();

    text-align: center;
}

.formSubtitle {
    color: colors.$text-color--gray;
    margin-bottom: 1rem;
    text-align: center;
}

.actions {
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    text-align: center;
}

.proAccountDescription {
    max-width: 80%;
}

.subtitle {
    @include mixins.heading-2();
}

.grecaptchaBadge {
    height: 0;
    visibility: hidden;
}
