@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/responsive';
@use '@/styles/resources/colors';
@use '@/styles/resources/z-index' as zIndexes;

$filterBar__index: zIndexes.$index--header - 2;
$filterBar__height: 3rem;

.root {
    background: var(--color--white, colors.$color--white);
    height: $filterBar__height; // prevent filters jumping on scroll up by constraining placeholder height
    position: sticky;
    top: calc(var(--header-mobile-height) + var(--header-general-height));
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    z-index: $filterBar__index;

    @include responsive.media('>tablet') {
        top: var(--header-general-height);
    }

    @include responsive.media('<=tablet') {
        margin-bottom: 1rem;

        &Hide {
            top: -20rem;
            transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        }
    }

    &Sticky {
        box-shadow: 0 6px 8px 0 rgba(32, 33, 36, 0.10);
    }

    &Open {
        z-index: zIndexes.$index--header;
    }
}

.container {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;

    @include responsive.media('<=mobile') {
        gap: 0.6em;
    }

    > * {
        align-self: auto;
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;

        &:nth-child(1) {
            flex-grow: 1;
            flex-shrink: 1;

            @include responsive.media('<tablet') {
                flex-grow: 0;
                flex-shrink: 1;
                order: 2;
            }
        }

        &:nth-child(2) {
            @include responsive.media('<tablet') {
                flex-grow: 1;
                flex-shrink: 1;
                order: -1;
            }
        }

        &:nth-child(3) {
            @include responsive.media('<tablet') {
                flex-shrink: 0;
                order: 1;
            }
        }
    }
}

:global(#hh) {
    .button {
        font-weight: vars.$font-weight--bold;
        gap: 0.5rem;
        height: $filterBar__height;
        overflow: visible;
        position: relative;
        text-transform: none;
        transition: font-weight 0.3s;
        word-break: break-all;

        > span {
            align-items: center;
            gap: 0.5rem;

            > span {
                align-items: center;
                display: flex;
                gap: 0.25rem;
            }
        }

        svg {
            flex: 0 0 18px;
        }

        &CloseSidebar {
            display: flex;
        }

        &OpenSidebar {
            display: none;
        }

        &Show {
            @include responsive.media('>tablet') {
                display: flex;
            }
        }

        &Hide {
            @include responsive.media('>tablet') {
                display: none;
            }
        }

        &Text {
            display: none;

            @include responsive.media('>tablet') {
                display: inline;
            }
        }
    }

    .totalItemsLabel {
        display: flex;
        gap: 0.25rem;

        p {
            align-self: baseline;
        }

        @include responsive.media('<=mobile') {
            overflow: hidden;
            white-space: nowrap;

            p + p {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .itemsSortContainer {
        align-items: center;
        display: flex;
        gap: 1rem;
    }
}
