@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use 'sass:color';

@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.checkboxIcon {
  align-items: center;
  background-color: colors.$color--white;
  border: 1px solid color.adjust(colors.$border-color--gray, $lightness: 30%);
  border-radius: 2px;
  color: colors.$text-color--dark-green;
  display: flex;
  flex: 0 0 1.25rem;
  height: 1.25rem;
  justify-content: center;
  transition: border-color 0.16s;
  width: 1.25rem;

  &.withBackground {
    svg {
      filter: invert(1);
      mix-blend-mode: luminosity;
    }

    &.isRound {
      border-color: transparent;
      @include mixins.swatch-border-overlay();
    }
  }

  svg {
    color: currentColor;
    visibility: hidden;
  }
}

.checkboxAsFilter {
  composes: checkboxIcon;
  flex: 0 0 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

.count {
  color: color.adjust(colors.$text-color--primary, $lightness: 60%);
  margin-left: -0.5rem;

  &::before {
    content: '(';
  }

  &::after {
    content: ')';
  }
}

.checkbox {
  display: grid;
  font-size: 0.875rem;
  gap: 0.5rem;
  position: relative;
}

.zeroWidthCheckboxWrapper {
  align-items: center;
  display: inline-flex;
}

.checkboxLabel {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  user-select: none;

  &Error {
    composes: checkboxLabel;

    .checkboxIcon {
      background-color: rgba(colors.$message-text--error, 0.1) !important;
      border-color: colors.$message-text--error;
    }
  }
}

.checkboxInput {
  @include mixins.a11y-visually-hidden();

  &:checked {
    ~ .checkboxLabel .checkboxIcon {
      color: colors.$text-color--dark-green;

      svg {
        visibility: visible;

        :global {
          animation: tick 0.18s ease-in-out both;
        }
      }
    }
  }

  &:disabled {
    ~ .checkboxLabel .checkboxIcon {
      background-color: colors.$color--gray-lightest;
      border-color: colors.$color--gray;
      border-width: 1px;
      box-shadow: none;
      cursor: default;

      svg {
        opacity: 0.4;
      }
    }

    ~ .checkboxLabel {
      opacity: 0.4;
    }
  }

  @include responsive.media('>tablet') {
    &:focus-visible {
      ~ .checkboxLabel .checkboxIcon {
        &:not(&.withBackground) {
          border-color: colors.$border-color--gray;
        }

        svg {
          visibility: visible;
        }
      }
    }
  }
}
