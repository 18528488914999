@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/fonts';

.wrapper {
    background: colors.$color--gray-lightest;
    padding: 2rem 1rem;
}

.container {
    display: grid;
    margin: 0 auto;
    max-width: 33.5rem;
    place-items: center;
}

.form {
    @include mixins.form-grid;
}

.title {
    @include mixins.heading-2();
    line-height: 1.5;
}

.message {
    font-size: 0.875rem;
}

.messageBold {
    font-size: 0.875rem;
    font-weight: fonts.$font-weight--bold;
}

.emailWrapper {
    display: grid;
    gap: 1rem;
}

.fieldLabel {
    font-size: 0.875rem;
    font-weight: fonts.$font-weight--medium;
    padding-bottom: 1rem;
}

.checkboxGroupWrapper {
    font-size: 0.875rem;
}

.checkboxWrap {
    display: flex;
    flex-direction: column;
}

.checkboxGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.radioGroup {
    & > div > div {
        display: flex;
        flex-wrap: wrap;
    }
}

.agreement {
    font-size: 0.875rem;
}

.agreementLink {
    @include mixins.link-underline($baseColor: colors.$text-color--primary, $hoverColor: colors.$text-color--primary, $hasUnderline: true);
}

.grecaptchaBadge {
    height: 0;
    visibility: hidden;
}
