@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';
@use '@/styles/resources/mixins';

.accountTitle {
    font-size: 1.5rem;
    font-weight: fonts.$font-weight--bold;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;

    @include responsive.media('>tablet', 'pointer-fine') {
        border-bottom: 1px solid colors.$border-color--primary;
        text-align: left;
    }

    @include mixins.no-print
}

.accountContainer {
    display: grid;
    grid-template-areas: "header" "aside" "main";
    grid-template-columns: 100%;
    grid-template-rows: auto;

    @include responsive.media('>tablet', 'pointer-fine') {
        column-gap: 4rem;
        grid-template-areas: "header header" "aside main";
        grid-template-columns: calc(15% - 1rem) calc(85% - 3rem);
    }

    @include responsive.media('print') {
        column-gap: 0 !important;
        grid-template-areas: "header" "aside" "main" !important;
        grid-template-columns: 100% !important;
    }
}

.accountAside {
    grid-area: aside;
    margin-bottom: 1.5rem;

    @include mixins.no-print
}

.accountContent {
    grid-area: main;
    min-height: 50vh;
}

.loadingContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 50vh;
}

@include responsive.media('>tablet', 'pointer-fine') {
    .accountHeading {
        grid-area: header;
        padding-bottom: 1rem;

        @include mixins.no-print
    }

    .accountContainer {
        column-gap: 4rem;
        display: grid;
        grid-template-areas: "header header" "aside main";
        grid-template-columns: calc(15% - 1rem) calc(85% - 3rem);
        grid-template-rows: auto;
    }
}


.skeleton {
    &Title {
        display: grid;
        gap: 0.5rem;
        margin-bottom: 2rem;
        padding-top: 1.4rem;

        @include responsive.media('>tablet') {
            border-bottom: 1px solid colors.$border-color--primary;
            padding-bottom: 1.4rem;
        }
    }
}
