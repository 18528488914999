@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.message {
    color: colors.$text-color--primary;
    font-size: 1rem;
    text-align: center;
    width: 100%;
}

.indicatorWrapper {
    display: grid;
    gap: 1rem;
    justify-content: center;
    text-align: center;
}

.indicator {
    animation-direction: alternate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 1rem;
}

@keyframes pulse {
    0% {
        filter: grayscale(1);
        transform: scale(0.75) rotate(-540deg);
    }

    100% {
        filter: grayscale(0);
        transform: scale(1) rotate(0deg);
    }
}

.loaderWrapper {
    &Full {
        display: flex;
        height: 100%;
        width: 100%;
    }

    & svg {
        height: 3rem;
        margin: 4rem auto 3rem;
        width: 5rem;
    }

    @include mixins.shimmer();
}
