@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

:global(#hh) {
    .container {
        @include mixins.no-print;
    }

    .productBreadcrumbsContainer {
        background-color: var(--color--white);
        margin-bottom: 0;

        @include mixins.no-print;

        @include vars.mq-medium-up {
            padding-bottom: 0.5rem;
        }
    }

    .productBreadcrumbsContainerWrap {
        padding: 0;
    }

    .categoryBreadcrumbsContainer {
        @extend .productBreadcrumbsContainer;
        padding: 1.5rem 0 0.5rem;
    }

    .containerWrap {
        display: grid;
    }

    .root {
        color: colors.$text-color--gray;
        display: flex;
        font-size: 0.875rem;
        list-style-type: none;
        overflow: hidden;
        padding: 1.5rem 0 0.5rem;
    }

    .categoryBreadcrumbsRoot {
        @extend .root;
        padding: 0;
    }

    .productBreadcrumbsRoot {
        color: var(--color--gray-800);
        display: flex;
        list-style-type: none;
        padding: 0;
    }

    .productPageRoot {
        @extend .root;
        display: grid;
        grid-template-columns: auto 1fr;
    }

    .text {
        + .text {
            &::before {
                color: colors.$text-color--primary;
                content: ' / ';
                text-decoration: none;
                white-space: pre;
            }
        }
    }

    .categoryText {
        @extend .text;
        color: var(--color--gray-700);
        font-size: 0.75rem;

        @include responsive.media('>mobile') {
            font-size: 0.85rem;
        }

        + .newText {
            &::before {
                color: var(--color--gray-700);

                content: '  /  ';
                font-size: 0.75rem;

                @include responsive.media('>mobile') {
                    font-size: 0.85rem;
                }
            }
        }
    }

    .productText {
        @extend .text;
        color: var(--color--gray-800);
        display: inline;
        font-size: 0.75rem;

        @include responsive.media('>mobile') {
            font-size: 0.85rem;
        }
    }

    .productLink {
        background-image: none;
        color: var(--color--gray-800);
        padding-bottom: 0;
        text-decoration: none;
    }

    .lastCrumbText {
        @extend .text;
        flex: 1;
        font-size: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include responsive.media('>mobile') {
            font-size: 0.85rem;
        }
    }

    .link {
        color: colors.$text-color--primary;

        &:hover {
            color: colors.$text-color--primary;
            text-decoration: underline;
        }
    }

    .categoryLink {
        color: var(--color--gray-700);

        &:hover {
            text-decoration: underline;
        }
    }
}
