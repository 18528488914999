@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/z-index' as zIndex;
@use '@/styles/resources/responsive';
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/utils/white-header' as whiteHeader;

.megaMenuLink {
    color: colors.$text-color--secondary;
    display: inline-block;
    font-weight: fonts.$font-weight--bold;
    padding: 1.5rem 0.75rem;
    position: relative;
    text-transform: uppercase;
}

.megaMenuLinkActive {
    text-decoration: underline;
    text-decoration-color: white;
    text-decoration-style: solid;
    text-decoration-thickness: 0.09375rem;
    text-underline-offset: 0.1875rem;
}

.megaMenuLinkText {
    display: flex;
    justify-content: center;

    &::after {
        border-color: transparent;
        border-style: solid;
        border-width: 0 0.375rem 0.438rem;
        bottom: -1px;
        content: '';
        height: 0;
        position: absolute;
        transition-delay: 0.16s;
        width: 0;
        will-change: border-color;
    }
}

.megaMenuItem {
    align-items: center;
    display: flex;
    justify-content: center;

    &:last-of-type {
        &::before {
            background-color: colors.$color--white;
            content: '';
            display: inline-block;
            height: 1.25rem;
            opacity: 0.25;
            width: 0.125rem;
        }
    }
}

.isItemHoveredOver {
    composes: megaMenuItem;

    & > div {
        // div here represents a submenu element of each megaMenuItem we hover over
        opacity: 1;
        top: 100%;
        visibility: visible;
        z-index: zIndex.$index--mega-menu-submenu-visible;
    }

    &:hover {
        &::after {
            border-bottom: 0.5rem solid colors.$color--wild-sand;
            border-left: 0.375rem solid transparent;
            border-right: 0.375rem solid transparent;
            bottom: -0.1%;
            content: '';
            position: absolute;
        }

        .megaMenuLink {
            &Active .megaMenuLinkText {
                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

// White header design
@include whiteHeader.white-header-media {
            .megaMenuLink {
                color: var(--color--gray-800, colors.$color--mine-shaft);
                padding: 1.1875rem;

                &.megaMenuLinkActive {
                    text-decoration-color: var(--color--gray-800, colors.$color--mine-shaft);
                }
            }

            .megaMenuItem {
                &.isItemHoveredOver {
                    &:hover {
                        &::after {
                            border-bottom-color: var(--color--gray-700);
                        }
                    }
                }

                &:last-of-type {
                    &::before {
                        background-color: colors.$color--mine-shaft;
                    }
                }
            }
}
